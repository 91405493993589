





















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import * as qs from 'query-string';

interface ErrorsMap {
    [error: string]: boolean
}

@Component
export default class RegisterView extends Vue {
    public enviando = false;
    public firstValidation = true;
    public customError = '';
    public termos = '';

    public dados = {
        nome : '',
        sobrenome : '',
        ddd : '',
        telefone : '',
        email : '',
        senha : '',
        senhaConfirma : '',
        termos: false,
    };

    public errors: ErrorsMap = {
        nome : false,
        sobrenome : false,
        ddd : false,
        telefone : false,
        email : false,
        senha : false,
        senhaConfirma : false,
        termos : false,
        custom : false,
    };

    public created () {
        fetch("/api/v1/termos.php", {
        })
        .then((response: Response) => {
          return response.text();
        })
        .then((data: string) => {
          return this.termos=data;
        })
        .catch((error: any) => {
          return console.log('error',error);
        });
    }

    public registrar () {
        this.enviando = true;
        this.firstValidation = false;
        this.errors.custom = false;

        if (this.valid) {
            fetch('/api/v1/indicadores.php',{
                method: 'POST',
                body: qs.stringify(this.dados),
            })
                .then( data => {
                    this.enviando = false;
                    return data.json();
                })
                .then( (data) => {
                    if (data.success) {
                        window.location.href = '/painel';
                    }
                    else {
                        this.errors.custom = true;
                        this.customError = data.message;
                    }
                });
        }
        else {
            this.enviando = false;
        }
    }

    get valid () {

        if ( this.firstValidation ) {
            return true;
        }

        this.errors.nome = this.dados.nome.length === 0;
        this.errors.sobrenome = this.dados.sobrenome.length === 0;
        this.errors.ddd = this.dados.ddd.length === 0;
        this.errors.telefone = this.dados.telefone.length === 0;
        this.errors.email = this.dados.email.length === 0;
        this.errors.senha = this.dados.senha.length === 0;
        this.errors.senhaConfirma = this.dados.senha !== this.dados.senhaConfirma;
        this.errors.termos = !this.dados.termos;

        return !Object.keys(this.errors).some(( value ) => this.errors[value]);
    }

    showTermos () {
        this.$modal.show('dialog', {
            title: 'Termos de Serviço',
            text: this.termos,
            buttons: [
                {
                    title: 'Aceito os termos',
                    default: true,
                    handler: () => { 
                        this.dados.termos = true;
                        this.$modal.hide('dialog');
                    }
                }, {
                    title: 'Close'
                }
            ]
        });
    }

}
