import Vue from 'vue';
import VueRouter from 'vue-router';
import StartView from "@/views/StartView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import RecuperarView from "@/views/RecuperarView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'default',
        component: LoginView,
    },
    {
        path: '/registrar',
        name: 'registrar',
        component: RegisterView,
    },
    {
        path: '/recuperar',
        name: 'recuperar',
        component: RecuperarView,
    },
];

const router = new VueRouter({
    routes
});

export default router
